import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './index.css';
import App from './App';

import Landing from "./components/Landing";
import OceanMap from "./components/OceanMap";
import OceanRegions from "./components/OceanRegions";
import CoralMap from "./components/CoralMap";

import AmtrakMap from "./components/amtrak/AmtrakMap";

import NotFound404 from "./components/NotFound404";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App>
        <Switch>
          <Route path="/" exact children={<Landing />} />
          <Route path="/scuba-map" children={<OceanMap />} />
          <Route path="/ocean-regions" children={<OceanRegions />} />
          <Route path="/coral-map" children={<CoralMap />} />

          <Route path="/amtrak" children={<AmtrakMap />} />

          <Route component={NotFound404} />
        </Switch>
      </App>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
