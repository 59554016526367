import { useState, useEffect } from "react";
// import styled from "styled-components";
import { spilhausSquare } from "./util";

import * as d3 from "d3-geo";
import * as topojson from "topojson-client";

import worldJSON from "../data/land-50m.json";

const points = [
  { title: "Similan Islands", lat: 8.6579, lon: 97.6467},
  { title: "Phuket", lat: 7.9519, lon: 98.3381},
  { title: "Cairns", lat: -16.9203, lon: 145.7710},
  { title: "Kona", lat: 19.7241, lon: -155.0868},
  { title: "Molokini", lat: 20.6323227, lon: -156.500348},
  { title: "Bali", lat: -8.3405, lon: 115.0920},
  { title: "Gili T", lat: -8.3483, lon: 116.0384},
  { title: "Hobart", lat: -42.8826, lon: 147.3257},
  { title: "Sydney", lat: -33.8688, lon: 151.2093},
  { title: "Milford Sound", lat: -44.6414, lon: 167.8974},
  { title: "Poor Knights", lat: -35.4719, lon: 174.7356},
  { title: "Santorini", lat: 36.3932, lon: 25.4615},
  { title: "Elphinstone", lat: 25.3095, lon: 34.8604},
  { title: "Brothers", lat: 26.3138, lon: 34.844},
  { title: "Cozumel", lat: 20.4230, lon: -86.9223},
  { title: "Dos Ojos", lat: 20.3252, lon: -87.3913},
  { title: "Cabo san Lucas", lat: 22.8905, lon: -109.9167},
  { title: "Cocos Island", lat: 5.5281669, lon: -87.0786347},
  { title: "Islas Murcielagos", lat: 10.8537721, lon: -85.9729818},
  { title: "Isa del Cano", lat: 8.7068511, lon: -83.8995947},
  { title: "BVI", lat: 18.4335, lon: -64.6333},
  { title: "Montego Bay", lat: 18.4762, lon: -77.8939},
  { title: "Providenciales", lat: 21.7738, lon: -72.2719},
  { title: "Bocas del Toro", lat: 9.4048, lon: -82.269},
  { title: "Sund Rock", lat: 47.4345249, lon: -123.1203807},
  { title: "Long Beach", lat: 33.7701, lon: -118.1937},
  { title: "Catalina", lat: 33.3879, lon: -118.4163},
  { title: "Santa Barbara Island", lat: 33.4756, lon: -119.0373},
  { title: "Monterey", lat: 36.6002, lon: -121.8947},
  { title: "Blue Hole NM", lat: 34.9410465, lon: -104.6770056}
]

function OceanMap() {
  const width = window.innerWidth * .7;
  const height = width;

  const [background, setBackground] = useState();
  const [land, setLand] = useState([]);
  const [lines, setLines] = useState([]);
  const [dots, setDots] = useState([]);

  useEffect(() => {
    const land = topojson.feature(worldJSON, worldJSON.objects.land);

    const graticule = d3.geoGraticule10();
    const sphere = { type: "Sphere" };

    const projection =  spilhausSquare().fitExtent(
        [[0, 0], [width, height]],
        sphere
      );

    const path = d3.geoPath(projection);

    setBackground(path(sphere));
    setLand(path(land))
    setLines(path(graticule));

    const newDots = points.map(p => {
      p.pixels = projection([p.lon, p.lat]);
      return p;
    })
    setDots(newDots);
    // eslint-disable-next-line
  }, []);

  // <rect width={width * 2} height={height*1.5} fill="#888" />
  // <circle key={`dot${i}`} cx={d.pixels[0]} cy={d.pixels[1]} r="3" fill="#f0f" />
  // C5F6F5
  // A6DED5
  const size = window.innerWidth > 800 ? 6 : 4;
  return (
    <div>
      <svg width={width*1.25} height={height*.9}>
        <g transform={`rotate(-45, ${width/2}, ${height/2}) translate(${width * .12}, ${width * .05})`}>
          <path d={background} fill="#555" />
          <path d={lines} stroke="#888" fill="transparent" />
          <path d={land} fill="#000" />
          { dots.map((d,i) => (
            <path key={`dot${i}`}
              fill="#ff0"
              d={`M0,0 -${size},-${size*2} ${size},-${size*2}Z`} 
              transform={`rotate(45, ${d.pixels.join(", ")}) translate(${d.pixels.join(", ")})`}
            />
          ))}
        </g>
      </svg>
    </div>
  );
}

export default OceanMap;
