import { useState, useEffect } from "react";
import * as d3 from "d3-geo";

import geoJSON from "./Amtrak_Routes.geojson";
import stationJSON from "./Amtrak_Stations.geojson";

import statesJSON from "./us-states.geojson";

function AmtrakMap() {
  const width = 1200;
  const height = 700;
  const margin = 50;

  const [routes, setRoutes] = useState([]);
  const [stations, setStations] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    // if (!originalProjection) return;
    fetch(geoJSON).then(response => response.json())
      .then(data => {

        const originalProjection =  d3.geoAlbers().fitExtent(
          [[0, 0], [width - margin*2, height - margin*2]],
          data
        );

        const path = d3.geoPath(originalProjection);

        const r = data.features
        .map(d => {
          return {
            d: path(d),
            id: d.properties.NAME,
          }
        });

        setRoutes(r);

        fetch(stationJSON).then(response => response.json())
          .then(stationData => {
            const s = stationData.features
            .filter(d => d.properties.stntype === "TRAIN")
            .map(d => {
              return {
                point: originalProjection(d.geometry.coordinates),
                name: d.properties.stationnam
              }
            })

            setStations(s);
          })

        fetch(statesJSON).then(response => response.json())
          .then(stateData => {
            const s = stateData.features
            .map(d => {
              return {
                d: path(d),
                name: d.properties.name
              }
            })
            setStates(s);
          })
      })

      // eslint-disable-next-line
  }, []);


  const colors = ["#DFFF00", "#FFBF00", "#FF7F50", "#DE3163", "#9FE2BF", "#40E0D0", "#6495ED", "#CCCCFF"];

  return (
    <div>
      <div style={{ display: "flex", margin: "40px" }}>
        <svg width={width} height={height}>
          <g transform={`translate(${margin},${margin})`}>
            <g>
              {states.map((d,i) => (
                <path d={d.d} className={d.name} stroke="#555" strokeWidth="1" fill="none" key={`state${i}`} />
              ))}
            </g>
            <g>
              {stations.map((d,i) => (
                <circle cx={d.point[0]} cy={d.point[1]} className={d.name} r="4" fill="none" stroke="#bbb" key={`station${i}`} />
              ))}
            </g>
            <g>
              {routes.map((d,i) => (
                <path d={d.d} className={d.id} stroke={colors[i%colors.length]} strokeWidth="2" fill="none" key={`route${i}`} />
              ))}
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AmtrakMap;
