import { useState, useEffect } from "react";
import styled from "styled-components";

import taxonomy from "../data/taxonomy.json";

const UL = styled.ul`
  .description {
    color: #ccc;
    font-style: italic;
  }
  li {
    cursor: pointer;
  }
`;

function Taxonomy({ family, setFamily }) {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    const n = taxonomy.map((t,i) => {
      t.open = true;
      t.children = t.children.map((u,j) => {
        u.open = true;
        u.children = u.children.map((v,k) => {
          v.open = false;
          return v;
        })
        return u;
      })
      return t;
    })
    setTreeData(n)
  }, []);

  return (
    <div style={{ maxHeight: "600px", overflow: "scroll", marginRight: "20px" }}>
      <UL>
        {treeData.map((t,i) => {
          return (
            <li key={`key${i}`}>
              {t.title}
              {t.description && <div className="description">{t.description}</div>}
              {t.open && (
                <ul>
                  {t.children.map((u,j) => (
                    <li key={`key${i}-${j}`}>
                      {u.title}
                      {u.description && <div className="description">{u.description}</div>}
                      {u.open && (
                        <ul>
                          {u.children.map((v,k) => (
                            <li key={`key${i}-${j}-${k}`}
                              onClick={() => setFamily(v.title)}
                              style={{
                                background: family === v.title ? "#ff0" : "transparent",
                                color: family === v.title ? "black" : "white",
                              }}
                            >
                              {v.title}
                              {v.description && <div className="description">{v.description}</div>}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )
        })}
      </UL>
    </div>
  );
}

export default Taxonomy;
