import { Link } from "react-router-dom";
import styled from "styled-components";

import wave from "./wave.png"

const Wrapper = styled.div`
  padding: 20px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  a { color: white; }
  ul { font-size: 1.5em; margin-bottom: 0; }
  li { margin-bottom: 10px; }
  li:before { content: '🌊'; margin-left: -20px; margin-right: 20px; } 
`;
const Background = styled.div`
  background-image: url(${wave});
  background-size: contain;
  background-repeat: no-repeat;
  width: 760px;
  height: 600px;

  @media (max-width: 600px) {
    width: 400px;
    height: 340px;
  }
`;
const H1 = styled.h1`
  position: absolute;
  top: 240px;
  left: 370px;
  width: 300px;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 18px;
    top: 150px;
    left: 200px;
    width: 200px;
  }
`;

function Landing() {
  const links = [
    { route: "/scuba-map", title: "Scuba Map" },
    { route: "/ocean-regions", title: "Ocean Regions" },
    { route: "/coral-map", title: "Coral Map" },
  ];
  return (
    <Wrapper>
      <div>
        <Background />
        <H1>rachel is making maps of the ocean</H1>
      </div>

      <ul>
        { links.map((l,i) => (
          <li key={`link${i}`}>
            <Link to={l.route}>{l.title}</Link>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
}

export default Landing;
