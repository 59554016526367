import { useState, useEffect } from "react";
import { spilhausSquare } from "./util";

import * as d3 from "d3-geo";
import * as topojson from "topojson-client";

import Taxonomy from "./Taxonomy";

import worldJSON from "../data/land-50m.json";
import coralList from "../data/coral_geo_small.json";

function CoralMap() {
  // const width = window.innerWidth * .7;
  const width = 600;
  const height = width;
  const sphere = { type: "Sphere" };

  // d3.geoNaturalEarth1
  // spilhausSquare

  const originalProjection =  spilhausSquare().fitExtent(
      [[0, 0], [width, height]],
      sphere
    );
  const landFeatures = topojson.feature(worldJSON, worldJSON.objects.land);

  const [background, setBackground] = useState();
  const [land, setLand] = useState([]);
 
  const [corals, setCorals] = useState([]);
  const [dots, setDots] = useState([]);
  const [family, setFamily] = useState([]);

  const [image, setImage] = useState("");

  // const [hover, setHover] = useState();
  // const [projection, setProjection] = useFunctionAsState(originalProjection);

  useEffect(() => {
    // if (!projection) return;

    const path = d3.geoPath(originalProjection);

    setBackground(path(sphere));
    setLand(path(landFeatures));

    let newDots = [];
    coralList.features.forEach(f => {
      // console.log(f);
      if (f.geometry.type === "Point") {
        newDots.push({
          properties: f.properties,
          c: originalProjection(f.geometry.coordinates)
        })
      } else {
        f.geometry.coordinates.forEach(c => {
          newDots.push({
            properties: f.properties,
            c: originalProjection(c)
          })
        })
      }

      setCorals(newDots);
    })

    // const properties = coralList.features.map(r => r.properties);

    // const m1 = [...new Set(properties.map(p => p.FAMILY))];
    // console.log("family", m1)
    // const m2 = [...new Set(properties.map(p => p.GENUS))];
    // console.log("genus", m2)

      // eslint-disable-next-line
  }, []);

  useEffect(() => {

    const url = `http://en.wikipedia.org/w/api.php?action=query&origin=*&titles=${family}&prop=pageimages&format=json&pithumbsize=300`;
    fetch(url)
      .then(response => response.json())
      .then(result => {
        if (result && result.query) {
          const id = Object.keys(result.query.pages)
          const page = result.query.pages[id];
          if (page.thumbnail)
            setImage(page.thumbnail.source);
          else
            setImage("");
        }
      })

    const newDots = corals.filter(f => f.properties.FAMILY === family)
    setDots(newDots);
    // eslint-disable-next-line
  }, [family]);

  return (
    <div>
      <h2 style={{ marginLeft: "20px" }}>click on a coral family name to display it on the map</h2>
      <div style={{ display: "flex" }}>
        <Taxonomy setFamily={setFamily} family={family} />
        <svg width={width} height={height}>
          <path d={background} fill="#555" />
          <g>
            {dots.map((d,i) => (
              <circle cx={d.c[0]} cy={d.c[1]} r="2" fill="#ff0" key={`coral${i}`} />
            ))}
            <path d={land} fill="#000" />
          </g>
        </svg>
        <div style={{ marginLeft: "20px" }}>
          { !!image.length &&
            <img src={image} alt="coral-preview" />
          }
        </div>
      </div>
    </div>
  );
}

export default CoralMap;


// function useFunctionAsState(fn) {

//   const [val, setVal] = useState(() => fn);

//   function setFunc(fn) {
//     setVal(() => fn);
//   }

//   return [val, setFunc];
  
// }
