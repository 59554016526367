import { useState, useEffect } from "react";
import styled from "styled-components";
import { spilhausSquare } from "./util";

import * as d3 from "d3-geo";
import * as topojson from "topojson-client";

import worldJSON from "../data/land-50m.json";
import regionJSON from "../data/ocean-regions.json";

// const Legend = styled.div`
//   position: absolute;
//   top: 80px;
//   left: 10px;
//   background: white;
//   color: black;
//   padding: 10px;
//   > div {
//     display: flex;
//     margin-bottom: 2px;
//   }
// `;
// const Box = styled.div`
//   display: inline-block;
//   width: 20px;
//   height: 20px;
//   margin-right: 5px;
// `;
const Hover = styled.table`
  position: fixed;
  top: 100px;
  right: 100px;
  background: white;
  color: black;
  padding: 10px;
`;
const Path = styled.path`
  stroke: white;
  cursor: pointer;
  &:hover { opacity: .7; }
`;

// const biome = ["Boundary - western", "Polar", "Boundary - eastern", "Semi-enclosed sea", "Equatorial", "Gyre", "Transitional"]
//     .sort((a,b) => {
//       if (a > b) return 1;
//       else return -1;
//     });
const realm = ["Indo-Pacific Warm Water", "Southern Cold Water", "Northern Cold Water", "Atlantic Warm Water", "Arctic", "Temperate Northern Atlantic", "Temperate Northern Pacific", "Tropical Atlantic", "Western Indo-Pacific", "Central Indo-Pacific", "Eastern Indo-Pacific", "Tropical Eastern Pacific", "Temperate South America", "Temperate Southern Africa", "Temperate Australasia", "Southern Ocean"];

function OceanRegions() {
  const width = window.innerWidth * .7;
  const height = width;
  const sphere = { type: "Sphere" };

  const originalProjection =  spilhausSquare().fitExtent(
      [[0, 0], [width, height]],
      sphere
    );
  const landFeatures = topojson.feature(worldJSON, worldJSON.objects.land);
  const region = topojson.feature(regionJSON, regionJSON.objects.regions);

  // const [background, setBackground] = useState();
  const [land, setLand] = useState([]);
  // const [lines, setLines] = useState([]);
  const [regions, setRegions] = useState([]);

  const [hover, setHover] = useState();
  const [projection, setProjection] = useFunctionAsState(originalProjection);

  useEffect(() => {
    if (!projection) return;

    console.log(projection)
    // const graticule = d3.geoGraticule10();
    const path = d3.geoPath(projection);

    // setBackground(path(sphere));
    setLand(path(landFeatures));


    const rs = region.features.map(r => {
      const proj = d3.geoEqualEarth().fitExtent(
        [[0, 0], [width, height]],
        r
      )

      // const projectedPath = path(r);
      // const centroid = path.centroid(r);

      // // console.log(centroid)

      // let visible = true;
      // if (centroid[0] < -width || centroid[0] > width*2) visible = false;
      // else if (centroid[0] < -height || centroid[1] > height*2) visible = false;


      // // console.log(path(r))
      // if (visible)
        return {
          properties: r.properties,
          path: path(r),
          projection: proj
        }
    }).filter(x => x)//.filter(r => r.properties.BIOME.length);
    setRegions(rs)

    // const properties = rs.map(r => r.properties);

    // const m1 = [...new Set(properties.map(p => p.BIOME))];
    // const m2 = [...new Set(properties.map(p => p.ECOREGION))];
    // const m3 = [...new Set(properties.map(p => p.PROVINC))];
    // const m4 = [...new Set(properties.map(p => p.REALM))];
    // const m5 = [...new Set(properties.map(p => p.TYPE))];

    // console.log(m1, m2, m3, m4, m5)

    // setLines(path(graticule));
      // eslint-disable-next-line
  }, [projection]);

  // const size = window.innerWidth > 800 ? 6 : 4;
  const colors = ["#DFFF00", "#FFBF00", "#FF7F50", "#DE3163", "#9FE2BF", "#40E0D0", "#6495ED", "#CCCCFF"]

  // colors[biome.indexOf(r.properties.BIOME)]
  // colors[i%8]

  /*
    <Legend>
        {biome.map((b,i) => (
          <div key={`biome${i}`}>
            <Box style={{ background: colors[i] }} />
            {b}
          </div>
        ))}
      </Legend>
  */

  // transform={`rotate(-45, ${width/2}, ${height/2}) translate(${width * .12}, ${width * .05})`}

  return (
    <div>
      <button onClick={() => setProjection(originalProjection)}>reset</button>
      <svg width={width*1.25} height={height}>
        <g >
          { regions.map((r,i) => (
              <Path
                key={`region${i}`}
                d={r.path}
                fill={colors[realm.indexOf(r.properties.REALM) % 8]}
                onMouseEnter={() => setHover(r.properties)}
                onClick={() => setProjection(r.projection)}
              />
          ))}
          <path d={land} fill="#000" />
        </g>
      </svg>
      { hover && (
        <Hover>
          <tbody>
            <tr>
              <td>BIOME</td>
              <td>{hover.BIOME}</td>
            </tr>
            <tr>
              <td>ECOREGION</td>
              <td>{hover.ECOREGION}</td>
            </tr>
            <tr>
              <td>PROVINC</td>
              <td>{hover.PROVINC}</td>
            </tr>
            <tr>
              <td>REALM</td>
              <td>{hover.REALM}</td>
            </tr>
          </tbody>
        </Hover>
      )}
    </div>
  );
}

export default OceanRegions;


function useFunctionAsState(fn) {

  const [val, setVal] = useState(() => fn);

  function setFunc(fn) {
    setVal(() => fn);
  }

  return [val, setFunc];
  
}
